// src/components/BusinessInfo.js
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box,
    TextField,
    Button,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material';

function BusinessInfo() {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const mock = urlParams.get('mock');
    const isMock = mock === 'true';

    const [businessInfo, setBusinessInfo] = useState({
        name: '',
        phone: '',
        logoUrl: '',
        prepTime: '30',
    });
    const [addressInfo, setAddressInfo] = useState({
        address1: '',
        address2: '',
        city: '',
        country: '',
        fullAddress: '',
        placeId: '',
        geoLocation: { lat: '', lng: '' },
        state: '',
        zipCode: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));

        if (!selectedLocation) {
            navigate('/locations');
            return;
        }

        const initialBusinessInfo = {
            name: selectedLocation.name,
            phone: selectedLocation.phone || '',
            logoUrl: selectedLocation.logoUrl || '',
            prepTime: '30',
        };

        const initialAddressInfo = {
            address1: selectedLocation?.address?.streetAddress?.name || '12228 Sherman Way',
            address2: selectedLocation?.address?.streetAddress?.apt || '',
            city: selectedLocation?.address?.city || 'North Hollywood',
            country: selectedLocation?.address?.country || 'USA',
            fullAddress: selectedLocation?.address?.formattedAddress || '12228 Sherman Way, North Hollywood, CA 91605',
            placeId: selectedLocation.id,
            geoLocation: {
                lat: selectedLocation?.address?.geocode?.latitude || '34.200230',
                lng: selectedLocation?.address?.geocode?.longitude || '-118.399590',
            },
            state: selectedLocation?.address?.subdivision || '',
            zipCode: selectedLocation?.address?.postalCode || '91605',
        };

        setBusinessInfo(initialBusinessInfo);
        setAddressInfo(initialAddressInfo);
    }, [navigate]);

    const handleBusinessChange = (e) => {
        const { name, value } = e.target;
        setBusinessInfo((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setAddressInfo((prev) => ({ ...prev, [name]: value }));
    };

    const handleBack = () => {
        navigate(`/locations${isMock ? '?mock=true' : ''}`);
    };

    const handleNext = () => {
        // Basic validation
        if (
            !businessInfo.name ||
            !businessInfo.phone ||
            !addressInfo.address1 ||
            !addressInfo.city ||
            !addressInfo.state ||
            !addressInfo.zipCode
        ) {
            setErrorMessage('Please fill out all required fields.');
            setOpenSnackbar(true);
            return;
        }

        // Phone number validation: only allow numbers, spaces, '+', '(', ')', and '-'
        const phoneRegex = /^[0-9\s()+-]+$/;
        if (!phoneRegex.test(businessInfo.phone)) {
            setErrorMessage(
                'Please enter a valid phone number containing only numbers, spaces, +, (, ), and -.'
            );
            setOpenSnackbar(true);
            return;
        }

        // Save the updated business and address info
        localStorage.setItem('businessInfo', JSON.stringify(businessInfo));
        localStorage.setItem('addressInfo', JSON.stringify(addressInfo));

        // Navigate to the next step
        navigate(`/userinfo${isMock ? '?mock=true' : ''}`);
    };

    return (
        <Box sx={{ p: { xs: 2, md: 3 } }}>
            <Typography variant="h4" gutterBottom>
                Business Information
            </Typography>
            <Box component="form" noValidate autoComplete="off">
                {/* Business Information Fields */}
                <Typography variant="h6" gutterBottom>
                    Business Details
                </Typography>
                <TextField
                    label="Business Name"
                    name="name"
                    value={businessInfo.name}
                    onChange={handleBusinessChange}
                    required
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Phone Number"
                    name="phone"
                    value={businessInfo.phone}
                    onChange={handleBusinessChange}
                    required
                    fullWidth
                    margin="normal"
                    placeholder="Enter your phone number"
                />
                <TextField
                    label="Preparation Time (minutes)"
                    name="prepTime"
                    value={businessInfo.prepTime}
                    onChange={handleBusinessChange}
                    fullWidth
                    margin="normal"
                />
                {/* Address Information Fields */}
                <Typography variant="h6" gutterBottom mt={4}>
                    Address Details
                </Typography>
                <TextField
                    label="Address Line 1"
                    name="address1"
                    value={addressInfo.address1}
                    onChange={handleAddressChange}
                    required
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Address Line 2"
                    name="address2"
                    value={addressInfo.address2}
                    onChange={handleAddressChange}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="City"
                    name="city"
                    value={addressInfo.city}
                    onChange={handleAddressChange}
                    required
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="State"
                    name="state"
                    value={addressInfo.state}
                    onChange={handleAddressChange}
                    required
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Zip Code"
                    name="zipCode"
                    value={addressInfo.zipCode}
                    onChange={handleAddressChange}
                    required
                    fullWidth
                    margin="normal"
                />
                <Box mt={3} textAlign="right">
                    <Button
                        variant="outlined"
                        onClick={handleBack}
                        sx={{ mr: 2 }}
                    >
                        Back
                    </Button>
                    <Button variant="contained" onClick={handleNext}>
                        Next
                    </Button>
                </Box>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="warning"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default BusinessInfo;

// src/App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OAuthRedirect from './components/OAuthRedirect';
import LocationSelection from './components/LocationSelection';
import UserInfo from './components/UserInfo';
import Overview from './components/Overview';
import Completion from './components/Completion';
import Start from './components/Start';
import Layout from './components/Layout';
import BusinessInfo from './components/BusinessInfo';
import Landing from './components/Landing';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/start" element={<Start />} />
          <Route path="/oauth" element={<OAuthRedirect />} />
          <Route path="/locations" element={<LocationSelection />} />
          <Route path="/business-info" element={<BusinessInfo />} /> {/* New Route */}
          <Route path="/userinfo" element={<UserInfo />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/complete" element={<Completion />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
